<template>
  <div class="basic-info-page">
    <m-page-title
      title="Update Personal Profile"
      describe="*indicates required field"
    />
    <h3 class="item-title">Basic information</h3>
    <el-form
      :model="fromData"
      ref="Form"
      :rules="rules"
      label-position="top"
      size="medium"
      @keyup.enter.native="saveClick"
      @submit.native.prevent
    >
      <el-form-item label="Status">
        <select-status
          :value="fromData.memberType"
          disabled
          class="form-input"
        />
      </el-form-item>
      <el-form-item label="Phone" prop="phone">
        <el-input v-model="fromData.phone" class="form-input"></el-input>
      </el-form-item>
      <el-form-item label="First Name" prop="fullName">
        <el-input v-model="fromData.fullName" class="form-input"></el-input>
      </el-form-item>
      <el-form-item label="Last Name" prop="lastName">
        <el-input v-model="fromData.lastName" class="form-input"></el-input>
      </el-form-item>
    </el-form>
    <div class="flex-row btn-box">
      <m-back />
      <el-button class="save-btn" type="primary" @click="saveClick">
        Save
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import SelectStatus from "@/components/SelectStatus";
import validate from "@/utils/validateForm";

export default {
  name: "basicInfo",
  components: { SelectStatus },
  data() {
    // 表单校验
    this.rules = {
      phone: [
        { required: true, message: "Phone cannot be empty", trigger: "blur" },
        { validator: validate.phone, trigger: "blur" },
      ],
      fullName: [
        {
          required: true,
          message: "First name cannot be empty",
          trigger: "blur",
        },
        { validator: validate.name, trigger: "blur" },
      ],
      lastName: [
        {
          required: true,
          message: "Last name cannot be empty",
          trigger: "blur",
        },
        { validator: validate.name, trigger: "blur" },
      ],
    };
    return {
      fromData: {
        memberType: null,
        phone: null,
        fullName: null,
        lastName: null,
      },
    };
  },
  computed: {
    ...mapState("user", ["userInfo"]),
    nameArr() {
      return this.userInfo.nickName.split(",");
    },
  },
  created() {
    this.init();
  },
  methods: {
    ...mapActions("user", ["getUserInfo"]),
    async saveClick() {
      // this.$axios.delete();
      const validate = await this.$refs.Form.validate();
      if (!validate) return false;
      const data = {
        nickName: this.fromData.fullName + "," + this.fromData.lastName,
        memberType: this.fromData.memberType.toString(),
        boundPhone: this.fromData.phone,
      };
      this.$axios.put(this.$api.updateMember, data).then((res) => {
        if (res.code === "1000") {
          this.$notify.success("Saved successfully");
          this.getUserInfo();
        }
      });
    },
    init() {
      const userinfo = this.userInfo;
      this.fromData.memberType = userinfo.memberType;
      this.fromData.phone = userinfo.boundPhone;
      this.fromData.fullName = this.nameArr[0];
      this.fromData.lastName = this.nameArr[1];
    },
  },
};
</script>

<style scoped lang="scss" src="./scss.scss"></style>
